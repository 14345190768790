import { Component, OnInit} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ActivatedRoute,
  Params,
  Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from './core/services/utils.service';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';

import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  flag: any = 'false';
  errFlag: any = 'false';
  tempFlag: any;
  errM: string = null;
  isShow: boolean = false;
  typeSelected: string;
  getBoolean: any;
  subscription: Subscription;
  token: any;
  isAdmin: boolean = false;
  error: string;

  constructor(
    private readonly http: HttpClient,
    private readonly activatedRoute: ActivatedRoute,
    private readonly spinnerService: NgxSpinnerService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly auth: AuthService
  ) {
    this.typeSelected = 'ball-clip-rotate-pulse';

    this.auth.getAccessTokenSilently().subscribe((res: any) => {
      localStorage.setItem('token', res);
      if (!sessionStorage.getItem('jwtToken')) {
        sessionStorage.clear();
        sessionStorage.setItem('jwtToken', localStorage.getItem('token'));
      }
      this.getBoolean = false;
    });
  }

  getUserByAuthId(): Observable<any> {
    const headers = new HttpHeaders({
      authId: localStorage.getItem('id'),
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
      headers: headers,
    });
  }

  ngOnInit(): void {
    this.spinnerService.show();
    let urlString = window.location.href;
    console.log(urlString);
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params.token;
      if (token != undefined) {
        sessionStorage.setItem('parentToken', token);
      }
    }); 
    urlString = urlString.replace('%253F','?');
    urlString = urlString.replace('%253D','=');
    
    if(urlString.indexOf('token') != -1) {
      if(urlString?.split("/").length > 4) {
        let reqIdStr = urlString?.split("/")[4];
        let reqId = reqIdStr?.split("?")[0];
        //let token = reqId.concat('~').concat(reqIdStr?.split("?")[1]);
        sessionStorage.setItem('parentToken', reqIdStr?.split("=")[1]);
        this.router.navigate(['/pairingAsProduct/', reqId]);
      } else {
        this.location.replaceState('pairingAsProduct');
      }
     
    } else if(urlString.indexOf('pairingAsProduct/') == -1) {
      this.location.replaceState('pairingAsProduct');
    } else {
      let reqIdStr = urlString?.split("/")[4];
      this.router.navigate(['/pairingAsProduct/', reqIdStr]);
    }
    
    //this.cleanURL(urlString.split("/")?.[1], reqId[0]);
   
    if(sessionStorage.getItem('userRole')){
      return;
    } else {
      this.auth.user$.subscribe((user) => {      
        sessionStorage.setItem('id', user?.sub);      
        const headers = new HttpHeaders({
          authId: sessionStorage.getItem('id'),
        });
        return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
          headers: headers,
        }).subscribe((res: any) => {  
          const id = res.result[0];
          const userRole = res.result[1];
          sessionStorage.setItem('userRole', userRole);
          sessionStorage.setItem('userId', id);
          this.utilService.userId(id);
          this.error = '';
          location.reload();
        },

        (err: any) => {
          this.spinnerService.hide();
          if(this.errM !== null || this.errM !== undefined || this.errM) {
            if (err.status == 401 || err.status == 403) {
              this.error =
                'Authentication Failed! due to some technical error, Please try to relogin.';
            } else if (err.status.startsWith('5')) {
              this.error =
                'Service unavailable due to Internal Server Error.';
            }
          }
             
        });
      });
    }
    

    this.utilService.showPopup.subscribe((res: any) => {
      this.spinnerService.hide();
      this.getBoolean = res;
    });

    this.spinnerService.show();
    this.utilService.SharingData.subscribe((res: any) => {
      this.spinnerService.hide();
      this.errM = res;
    });
  }

  cleanURL() {
    this.location.replaceState('home');
  }

  
}
