export const countryDropdownSettings = {
  singleSelection: false,
  idField: 'countryId',
  textField: 'countryNamePfx',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

export const businessLineDropdownSettings = {
  singleSelection: true,
  idField: 'businessLineId',
  textField: 'businessLineName',
  itemsShowLimit: 1,
  limitSelection: 1,
  allowSearchFilter: true,
};

export const productLineDropdownSettings = {
  singleSelection: false,
  idField: 'code',
  textField: 'description',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 4,
  limitSelection: 1,
  allowSearchFilter: true,
};

export const michelinTyreUsageDropdownSettings = {
  singleSelection: false,
  idField: 'usageId',
  textField: 'usageName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

export const seasonDropdownSettings = {
  singleSelection: false,
  idField: 'subSeasonId',
  textField: 'subSeason',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

export const michelinBrandDropdownSettings = {
  singleSelection: false,
  idField: 'brandId',
  textField: 'brandName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

export const michelinTyreLineDropdownSettings = {
  singleSelection: false,
  idField: 'categoryName',
  textField: 'categoryName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

// other brand setings
export const otherBrandDropdownSettings = {
  singleSelection: false,
  idField: 'brandId',
  textField: 'brandName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

// other season setings
export const otherSeasonDropdownSettings = {
  singleSelection: false,
  idField: 'subSeasonId',
  textField: 'subSeason',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

// other tyre usage setings
export const otherTyreUsageDropdownSettings = {
  singleSelection: false,
  idField: 'usageId',
  textField: 'usageName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

// other tireline settings
export const otherTirelineDropdownSettings = {
  singleSelection: false,
  idField: 'categoryName',
  textField: 'categoryName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  allowSearchFilter: true,
};

// Price type settings
export const priceTypeDropdownSettings = {
  singleSelection: true,
  idField: 'id',
  textField: 'parameterName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  limitSelection: 1,
  closeDropDownOnSelection: false,
  allowSearchFilter: false,
};

// Order Preference settings
export const orderPreferenceDropdownSettings = {
  singleSelection: true,
  idField: 'id',
  textField: 'parameterName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 1,
  limitSelection: 1,
  closeDropDownOnSelection: false,
  allowSearchFilter: false,
};

export const multiStepFormArryAttr = [
  'productLine',
  'businessLine',
  'country',
  'brandX',
  'seasonX',
  'usageX',
  'tirelineX',
  'brandY',
  'seasonY',
  'usageY',
  'tirelineY',
  'priceType',
  'orderPreference',
];

export const Month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const IndexMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const stepMap = [
  { stage: 'Perimeter', step: 1 },
  { stage: 'Clustering', step: 2 },
  { stage: 'Degradation', step: 3 },
  { stage: 'Sorting', step: 4 },
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SAVE_DRAFT_MSG =
  'Request is not yet Saved. Do you want to Save?';
export const CLEARALL_MSG =
  'Request is not yet Saved. Do you want to Clear All the fields?';
export const UNMATCHED_CAI_DOWNLOAD_MSG =
  'Few CAIs are unmatched. You can view the list of unmatched CAIs in downloaded Unmatched CAI list';

export const PLATFORM = 'dmintplatform';
export const PLATFORM_FRONTEND = 'dmintplatformfrontend';
export const PLATFORM_API = 'dmintplatformapi';
export const DEV_PLATFORM_URL =
  'https://dev.platform.dacoval.com';
export const QA_PLATFORM_URL =
  'https://dmintplatformfrontend-qa.azurewebsites.net';
export const UAT_PLATFORM_URL =
  'https://dmintplatformfrontend-uat.azurewebsites.net';
export const PROD_PLATFORM_URL = 'https://dmintplatform.azurewebsites.net';
 
export const DEV_PLATFORM_API_URL =
  'https://dev-backend.platform.dacoval.com/dmint-platform/api';
export const QA_PLATFORM_API_URL =
  'https://dmintplatformapi-qa.azurewebsites.net/dmint-platform/api';
export const UAT_PLATFORM_API_URL =
  'https://dmintplatformapi-uat.azurewebsites.net/dmint-platform/api';
export const PROD_PLATFORM_API_URL = 'https://dmintplatformapi.azurewebsites.net/dmint-platform/api';


