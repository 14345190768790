import { Component, EventEmitter, Output } from '@angular/core';
import { UtilService } from '../../services/utils.service';

@Component({
  selector: 'month-picker',
  templateUrl: 'month-picker.component.html',
  styleUrls: ['month-picker.component.scss'],
})
export class MonthPickerComponent {
  @Output() monthRangeSelected = new EventEmitter<string>();

  currentYearIndex: number;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string;
    monthYear: number;
    isInRange: boolean;
    isLowerEdge: boolean;
    isUpperEdge: boolean;
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string;
    monthYear: number;
    isInRange: boolean;
    isLowerEdge: boolean;
    isUpperEdge: boolean;
  }>;
  globalIndexOffset: number;
  thirdLastMonth: any;
  secondLastMonth: any;
  LastMonth: any;
  currentMonth: any;
  EnableMonths: Array<string>;
  DisableMonths: Array<string>;
  AllMonths: Array<string>;

  isDisabled: boolean = true;
  currentYear: any;

  constructor(private readonly utilService: UtilService) {}

  onClick(indexClicked: any) {
    if (this.rangeIndexes[0] === null) {
      this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
    } else if (this.rangeIndexes[1] === null) {
      this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
      this.rangeIndexes.sort((a, b) => a - b);
      this.monthsData.forEach((month, index) => {
        if (this.rangeIndexes[0] <= index && index <= this.rangeIndexes[1]) {
          month.isInRange = true;
        }
        if (this.rangeIndexes[0] === index) {
          month.isLowerEdge = true;
        }
        if (this.rangeIndexes[1] === index) {
          month.isUpperEdge = true;
        }

      });
      let fromMonthYear = this.monthsData[this.rangeIndexes[0]];
      let toMonthYear = this.monthsData[this.rangeIndexes[1]];

      if (
        this.DisableMonths.includes(
          fromMonthYear.monthName + ' ' + fromMonthYear.monthYear
        ) ||
        this.DisableMonths.includes(
          toMonthYear.monthName + ' ' + toMonthYear.monthYear
        )
      ) {
        indexClicked.preventDefault();
        return;
      }
      this.emitData(
        `Range is: ${fromMonthYear.monthName} ${fromMonthYear.monthYear} to ${toMonthYear.monthName} ${toMonthYear.monthYear}`
      );
      this.isDisabled = false;
      this.utilService.openCalendarBox(false);
    } else {
      this.initRangeIndexes();
      this.initMonthsData();
      this.onClick(indexClicked);
      this.sliceDataIntoView();
    }
  }

  emitData(string: any) {
    this.monthRangeSelected.emit(string);
    this.isDisabled = true;
  }

  sliceDataIntoView() {
    this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
    this.monthDataSlice = this.monthsData.slice(
      this.globalIndexOffset,
      this.globalIndexOffset + 12
    );
  }

  incrementYear() {
    if (this.currentYearIndex !== this.years.length - 1) {
      this.currentYearIndex++;
      this.sliceDataIntoView();
    }
  }

  decrementYear() {
    if (this.currentYearIndex !== 0) {
      this.currentYearIndex--;
      this.sliceDataIntoView();
    }
  }

  initRangeIndexes() {
    this.rangeIndexes = [null, null];
  }

  initMonthsData() {
    this.monthsData = new Array();
    this.years.forEach((year) => {
      this.months.forEach((month) => {
        this.monthsData.push({
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false,
        });
      });
    });
  }

  initYearLabels() {
    this.currentYear = new Date().getFullYear();
    const range = (start: any, stop: any, step: any) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    this.years = range(this.currentYear - 1, this.currentYear, 1);
  }

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(`${i + 1}/1`).toLocaleDateString('en-US', {
        month: 'short',
      });
    });
  }

  initViewSlices() {
    this.monthViewSlicesIndexes = [];
    this.years.forEach((year, index) => {
      if (index === 0) {
        this.monthViewSlicesIndexes.push(0);
      } else
        this.monthViewSlicesIndexes.push(
          this.monthViewSlicesIndexes[index - 1] + 12
        );
    });
  }

  ngOnInit() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initViewSlices();
    this.initMonthsData();
    this.initRangeIndexes();
    this.currentYearIndex = this.years.findIndex(
      (year) => year === new Date().getFullYear()
    );
    this.sliceDataIntoView();
    this.getMonth();
  }

  getMonth() {
    const current = new Date();
    current.setDate(1);

    current.setMonth(current.getMonth() - 3);
    this.thirdLastMonth = current.toLocaleString('en-US', {
      month: 'short',
      year: 'numeric',
    });

    current.setMonth(current.getMonth() + 1);
    this.secondLastMonth = current.toLocaleString('en-US', {
      month: 'short',
      year: 'numeric',
    });

    current.setMonth(current.getMonth() + 1);
    this.LastMonth = current.toLocaleString('en-US', {
      month: 'short',
      year: 'numeric',
    });

    this.EnableMonths = [
      this.thirdLastMonth,
      this.secondLastMonth,
      this.LastMonth,
      // this.currentMonth,
    ];

    this.AllMonths = [];

    for (const year of this.years) {
      for (const month of this.months) {
        this.AllMonths.push(month + ' ' + year.toString());
      }
    }

    this.DisableMonths = this.AllMonths.filter(
      (o) => this.EnableMonths.indexOf(o) === -1
    );
    
  }
}
