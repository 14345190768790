<div class="container">
  <div class="header">
    <div class="mainNav">
      <div class="dashboard_div"  *ngIf="isAdminPage()" (click)="goToHome()" onkeypress="">
        <img
          src="../../assets/images/home_icon.svg"
          alt="home"
          width="16px"
          height="16px"
        /><span>Home</span>
      </div>
      <div class="dashboard_div"  *ngIf="isAdmin" (click)="goToAdmin()" onkeypress="">
        <img
          src="../../assets/images/adminSettings.svg"
          alt="admin"
          width="16px"
          height="16px"
        /><span>Admin Settings</span>
      </div>

    </div>
    <div class="logo_div">      
      <div class="logo_text">Pairing as a Product</div>
    </div>
    <div class="profile_div">
    </div>
  </div>
</div>
