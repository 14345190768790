import { BrowserModule } from '@angular/platform-browser';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './shared/auth-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { PairingStepFormComponent } from './core/components/pairing-step-form/pairing-step-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MonthPickerComponent } from './core/components/month-picker/month-picker.component';
import { FormatFileSizePipe } from './core/services/FormatFileSizePipe.pipe';
import { DropdownDirective } from './shared/dropdown.directive';
import { ErrorInterceptor } from './shared/error.interceptor';
import { UtilService } from './core/services/utils.service';
import { DownloadFileService } from './core/services/downloadFile.service';
import { HomeService } from './core/components/home/home.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastComponent } from './shared/toast/toast.component';
import { AdminComponent } from './core/components/admin/admin.component';
import { AdminApiService } from './core/components/admin/adminApi.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AlertComponent } from './shared/alert/alert.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { MenusComponent } from './core/components/menus/menus.component';
import { CookieService } from 'ngx-cookie-service';
import * as PairingConst from 'src/app/pairing.constants';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

export const authConfig = environment.type.includes('qa') ? environment.indusAuth 
    : ( environment.type.includes('uat') ? environment.uatAuth
    : environment.auth);
//export const authConfig =  environment.auth;

export const platformApiUrl =  environment.type.includes('qa') ? 
                               PairingConst.QA_PLATFORM_API_URL : 
                               (environment.type.includes('uat') ? 
                                  PairingConst.UAT_PLATFORM_API_URL : 
                                  PairingConst.DEV_PLATFORM_API_URL);

export const platformBEApiUrl = (platformApiUrl.includes(PairingConst.PLATFORM_FRONTEND) ? platformApiUrl.replace(PairingConst.PLATFORM_FRONTEND, PairingConst.PLATFORM_API) 
        : platformApiUrl.replace(PairingConst.PLATFORM, PairingConst.PLATFORM_API)).concat('/dmint-platform/api/v1');



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenusComponent,
    PairingStepFormComponent,
    MonthPickerComponent,
    FormatFileSizePipe,
    DropdownDirective,
    ToastComponent,
    AdminComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    NgxFileDropModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    AutocompleteLibModule,
    AuthModule.forRoot(authConfig),
    MatTooltipModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    HomeService,
    UtilService,
    DownloadFileService,
    AdminApiService,
    CookieService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
