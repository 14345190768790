import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  clusteringDataSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  degradationDataSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  headerTabs: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  tokenSource: BehaviorSubject<any> = new BehaviorSubject('');
  isAdmin: boolean = false;
  isCalendarPopUpOpen = new Subject<any>();

  showPopup = new BehaviorSubject<boolean>(false);
  SharingData = new Subject();

  tosterShow = new BehaviorSubject<boolean>(false);
  tosterMessage = new BehaviorSubject<any>('');
  getUserId = new BehaviorSubject<any>('');

  getClusteringCheckValues(data: any) {
    this.clusteringDataSource.next(data);    
  }

  getDegradationCheckValues(data: any) {
    this.degradationDataSource.next(data);
  }

  setHeaderTabs(headerTabs: any) {
    this.headerTabs.next(headerTabs);
  }

  openCalendarBox(value: any) {
    this.isCalendarPopUpOpen.next(value);
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  checkPopup(value: boolean) {    
    this.showPopup.next(value);
  }

  isTosterVisible(isVisible: any, message: any) {
    this.tosterShow.next(isVisible);
    this.tosterMessage.next(message);
  }

  
  getUserRole() {
    if(sessionStorage.getItem('userRole') === "Admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    return this.isAdmin;
  }

  userId(userId: any){
    this.getUserId.next(userId);
  }

}
