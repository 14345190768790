// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  type: window.location.origin,
  //apiUrl: 'https://pairingproductapi-dev.azurewebsites.net/pairing-product/api',
  //apiUrl: 'https://pairingproductapi-qa.azurewebsites.net/pairing-product/api',
  apiUrl: `${document.location.protocol}//${document.location.host}/pairing-product/api`,
  auth: {
    domain: 'dev-c2oymhxblfvzum2l.eu.auth0.com',
    clientId: 'aMseTbLYAHdoaoxtj1amJYd9LUIkbkvb',
    clientSecret:
      'xjKALYnHYWFyWUjaVA-QbEk7eioZ2wMx6AAwxNZSztihFaqecAcU8GATtVvXJYBn',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://pairing-dev.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  indusAuth: {
    domain: 'dacoval-qa.eu.auth0.com',
    clientId: 'aOeLlqVqwUy4YlvGqvdMwunkby8vCug0',
    clientSecret:
      'zxWdIqvA5_3fl0OhQH5f_kch0Pnd537xJc6pGhCjEcoyuyrM-NroJ6GbUvC8lNFh',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://pairing-qa.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  uatAuth: {
    domain: 'dacoval-dev.eu.auth0.com',
    clientId: 'NeaXeSZWKEN3RdO16aOtZjvJQUH0nYy0',
    clientSecret:
      '6lANbhqLdTqIY42vHGgQl-r5JtQNspmQ3l45O5Thonm6GJSkYhKK2rsz4dEiD4o3',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://pairing-uat.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
