import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilService } from '../core/services/utils.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly utilService: UtilService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage: any;

        if (error.error.code === 401 || error.error.code === 500) {
          errorMessage = error.error.result.error;
          this.utilService.SharingData.next(errorMessage);
        }

        if ([440].indexOf(error.status) !== -1) {
          if (error.status === 440) {
            errorMessage =
              'Session Expired! Please close this tab and try to re-login from DMINT Platform.';
            this.utilService.SharingData.next(errorMessage);
            this.utilService.checkPopup(true);
          }
        }

        if (
          error.error.code === 422 ||
          error.error.message === 'Unprocessable Entity'
        ) {
          errorMessage = error.error.result.error;
          this.utilService.SharingData.next(errorMessage);
        }

        if (error.error.code === 400) {
          errorMessage = error.error.result.error;
          this.utilService.SharingData.next(errorMessage);
        }

        if (error.error.code === 404 || error.error.message === 'Not Found') {
          errorMessage = error.error.result.error;
          this.utilService.SharingData.next(errorMessage);
        }

        return throwError(error.message);
      })
    );
  }
}
