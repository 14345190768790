import { Injectable } from '@angular/core';
import { PairingStepFormApi } from '../components/pairing-step-form/pairing-step-form.service';
import { HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  contentDispositionHeader: any;
  constructor(
    private readonly pairingApiService: PairingStepFormApi,
    private readonly spinnerService: NgxSpinnerService
  ) {}

  downloadCAIFile(userId: string) {
    this.spinnerService.show();
    let formData = new FormData();
    formData.append('userId', userId.toString());
    return this.pairingApiService.getCAIFile(formData).subscribe(
      (response: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.fileDownloadCommonService(response);
      },
      (err: any) => {
        this.spinnerService.hide();
      }
    );
  }

  downloadMissingCAI(fileName: any) {
    this.pairingApiService.getMissingCAIFile(fileName).subscribe(
      (response: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.fileDownloadCommonService(response);
      },
      (err: any) => {
        this.spinnerService.hide();
      }
    );
  }

  fileDownloadCommonService(response: any) {
    const contentDispositionHeader = response.headers
      .get('Content-Disposition')
      .split('filename=')[1]
      .split(';')[0];

    const blob = new Blob([response.body], {
      type: 'application/vnd.ms-excel',
    });
    const file = new File([blob], contentDispositionHeader, {
      type: 'application/vnd.ms-excel',
    });
    const url = window.URL.createObjectURL(blob);
    if(url.length == 0)
      console.log(file.size);
    FileSaver.saveAs(blob, contentDispositionHeader);
  }
}
