import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AdminApiService } from './adminApi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { UtilService } from '../../services/utils.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {


  currentYear: number;
  adminForm!: FormGroup;
  typeSelected: string;
  status: any = [];

  responseData: any;
  getResponse: any;
  public fileData: any;
  filterData: any = [];
  totalItems: any;

  lastRunFromStart: any;
  lastRunToEnd: any;
  statusCheck: any;
  isUpArrowActive: boolean = false;

  p: any = 0;
  currentPage: any;
  itemsPerPage: any = 10;

  userGroups: any = [];
  selectedUserGroup: any = [];
  selectedStatus: any = [];

  userGroupDropdownSettings: IDropdownSettings = {};
  statusDropdownSettings: IDropdownSettings = {};

  keyword = 'userName';
  searchRecord: any[] = [];
  filteredSearchValue: any;
  filteredSearchAccessGroup: any;
  error: any;
  isError: boolean = false;

  userGroupSearchkeyword = 'accessGroup';
  searchuserGroupRecord: any[] = [];

  isFilterPagination: boolean = false;
  isEditPopupVisible: boolean = false;
  userName: any;
  totalAccessGroup: any;
  accessGroupData: any[] = [];
  accessgroupByUserId: any = [];
  accessgroupByUserIdChip: any = [];
  isActivate: boolean = false;
  isDeactivate: boolean = false;
  isLoading: boolean = false;
  isChecked: boolean = false;
  onEditId: any;
  isStatusPopupVisible: boolean = false;
  getStatus: any;
  statusMessage: any;
  setStatus: any;
  isAssignButtonDisabled: boolean = false;
  getResponseGroup: any;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  headingText = '';
  successMsg = 'Changes to the user have been successfully saved.';
  statusMessageSure = '';
  isToastVisible: boolean = false;
  displayStyle = 'block';
  displayStyleOpacity = '1';
  changesFlag: boolean = false;
  storeStatus: any;
  displayOpacity: any = '1';
  onEditSearchDisabled: boolean = true;
  unAssignAll: boolean = false;
  isAdmin: boolean = false;
  isUnAssignLinkVisible: boolean = false;

  constructor(
    private readonly spinnerService: NgxSpinnerService,
    private readonly fb: FormBuilder,
    private readonly AdminApiService: AdminApiService,
    private readonly http: HttpClient,
    private readonly utilService: UtilService,
    private readonly router: Router
  ) {
    this.typeSelected = 'ball-clip-rotate-pulse';
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    // loading spinner show
    this.spinnerService.show();

    this.buildForm();
    this.isAdmin = this.utilService.getUserRole();

    if(!this.isAdmin){
      this.router.navigate(['/pairingAsProduct']);
    }


    this.utilService.SharingData.subscribe((res: any) => {
      this.error = res;
    });

    // userGroups call
    this.getUserGroup();
    this.fetchAllUserRecords();

    this.status = [
      { id: 1, statusValue: 'Active', disabled: false },
      { id: 2, statusValue: 'Inactive', disabled: false },
    ];

    this.userGroupDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'accessGroupName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'statusValue',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.AdminApiService.getUserListOnSearch().subscribe((res: any) => {
      const getRecord = res;
      this.searchRecord = getRecord.result;
    });
  }

  fetchAllUserRecords() {
    this.spinnerService.show();
    this.AdminApiService.getAllUserRecords().subscribe((res: any) => {
      this.spinnerService.hide();
      this.responseData = res;
      this.fileData = this.responseData.result;
      this.filterData = [...this.fileData];
      this.totalItems = this.responseData.totalCount;
    });
  }

  getUserGroup() {
    this.AdminApiService.getUserGroups().subscribe(
      (res) => {
        const userGroups = res;
        this.spinnerService.hide();
        this.userGroups = userGroups.result;
        this.userGroups = this.userGroups.filter(function (el: any) {
          return el !== 'NULL';
        });
        this.totalAccessGroup = this.userGroups.length;
      },
      (err) => {
        this.spinnerService.hide();
      }
    );
  }

  buildForm() {
    this.adminForm = this.fb.group({
      userName: new FormControl(null),
      searchAccessGroup: new FormControl(null),
      accessGroups: new FormArray([]),
      status: new FormArray([]),
      startRunDate: new FormControl(null),
      endRunDate: new FormControl(null),
      sortBy: new FormControl('userName'),
      sortType: new FormControl('ASC'),
      limit: new FormControl(this.itemsPerPage),
      page: new FormControl(),
    });
  }

  get accessGroups(): FormArray {
    return this.adminForm.get('accessGroups') as FormArray;
  }

  getPage(event: any) {
    this.spinnerService.show();
    this.p = event;

    if (this.p > 0) {
      this.currentPage = this.p - 1;
    } else {
      this.currentPage = this.p;
    }

    if (!this.isFilterPagination) {
      this.http
        .post(
          `${environment.apiUrl}/v1/userAccessGroups?limit=${this.itemsPerPage}&page=${this.currentPage}`,
          {}
        )
        .subscribe((res: any) => {
          this.spinnerService.hide();
          this.responseData = res;
          this.fileData = this.responseData.result;
          this.filterData = [...this.fileData];
          this.totalItems = this.responseData.totalCount;
        });
    } else {
      this.adminForm.patchValue({
        userName: this.filteredSearchValue,
        accessGroups: this.adminForm.get('accessGroups').value,
        status: null,
        startRunDate: this.adminForm.get('startRunDate').value,
        endRunDate: this.adminForm.get('endRunDate').value,
        sortBy: this.adminForm.get('sortBy').value,
        sortType: this.adminForm.get('sortType').value,
        limit: this.itemsPerPage,
        page: this.currentPage,
      });
      this.http
        .post<any>(
          `${environment.apiUrl}/v1/filterUserAccessGroups`,
          this.adminForm.value
        )
        .subscribe(
          (res: any) => {
            this.spinnerService.hide();
            this.responseData = res;
            this.fileData = this.responseData.result;
            this.filterData = [...this.fileData];
            this.totalItems = this.responseData.totalCount;
            this.isError = false;
          },
          (err: any) => {
            this.spinnerService.hide();
            this.isError = true;
          }
        );
    }
  }

  onUserGroupSelect(item: any) {
    const control = new FormControl(item.accessGroupName);
    (<FormArray>this.adminForm.get('accessGroups')).push(control);
    this.adminForm.patchValue({
      accessGroups: this.selectedUserGroup.accessGroupName,
    });
  }

  onUserGroupDeselect(item: any) {
    let i = 0;
    (<FormArray>this.adminForm.get('accessGroups')).controls.forEach(
      (l: any) => {
        if (l.value == item.accessGroupName) {
          (<FormArray>this.adminForm.get('accessGroups')).removeAt(i);
          return;
        }
        i++;
      }
    );
  }

  onSelectAllUserGroups(items: any) {
    (<FormArray>this.adminForm.get('accessGroups')).clear();
    for (let key of items) {
      const controls = new FormControl(key.accessGroupName);
      (<FormArray>this.adminForm.get('accessGroups')).push(controls);
    }
  }

  onDeSelectAllUserGroups() {
    (<FormArray>this.adminForm.get('accessGroups')).clear();
  }

  onStatusSelect(item: any) {
    const control = new FormControl(item.statusValue);
    (<FormArray>this.adminForm.get('status')).push(control);
    this.adminForm.patchValue({
      status: this.selectedStatus.statusValue,
    });
  }

  onStatusDeselect(item: any) {
    let i = 0;
    (<FormArray>this.adminForm.get('status')).controls.forEach((l: any) => {
      if (l.value == item.statusValue) {
        (<FormArray>this.adminForm.get('status')).removeAt(i);
        return;
      }
      i++;
    });
  }

  onSelectAllStatus(items: any) {
    (<FormArray>this.adminForm.get('status')).clear();
    for (let key of items) {
      const controls = new FormControl(key.statusValue);
      (<FormArray>this.adminForm.get('status')).push(controls);
    }
  }

  onDeSelectAllStatus() {
    (<FormArray>this.adminForm.get('status')).clear();
  }

  lastRunStartDate(event: any) {
    if (event.target.value !== null) {
      const date = new Date(event.target.value);
      const formatedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
      this.lastRunFromStart = formatedDate;
      this.adminForm.patchValue({
        startRunDate: formatedDate,
      });
    } else {
      this.lastRunFromStart = null;
      this.adminForm.patchValue({
        startRunDate: null,
      });
    }
  }

  lastRunEndDate(event: any) {
    if (event.target.value !== null) {
      const date = new Date(event.target.value);
      const formatedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
      this.lastRunToEnd = formatedDate;
      this.adminForm.patchValue({
        endRunDate: formatedDate,
      });
    } else {
      this.lastRunToEnd = null;
      this.adminForm.patchValue({
        endRunDate: null,
      });
    }
  }

  selectEvent(item: any) {
    this.filteredSearchValue = item.userName;
    this.adminForm.patchValue({
      userName: item.userName,
    });
    // filter call here
    this.onFilter();
  }

  onChangeSearch(val: string) {
    this.filteredSearchValue = val;
    this.adminForm.patchValue({
      userName: this.filteredSearchValue,
    });
    this.onFilter();
  }

  onFocused(e: any) {
    // console.log(e);
    // do something when input is focused
  }

  selectEventAccessGroup(item: any) {
    this.filteredSearchAccessGroup = item.accessGroup;
    this.adminForm.patchValue({
      searchAccessGroup: item.accessGroup,
    });
    this.onAccessGroupSearch();
  }

  onChangeAccessGroup(val: string) {
    this.filteredSearchAccessGroup = val.toLocaleUpperCase();
    this.adminForm.patchValue({
      searchAccessGroup: this.filteredSearchAccessGroup,
    });
    this.onAccessGroupSearch();
  }

  onAccessGroupSearch() {
    this.spinnerService.show();
    this.AdminApiService.getAllAccessGroupsOnSearchInEdit(
      this.onEditId,
      this.filteredSearchAccessGroup
    ).subscribe((res: any) => {
      this.spinnerService.hide();
      this.getResponse = res;
      this.accessGroupData = this.getResponse.result;
      this.accessgroupByUserId = [...this.accessGroupData];
      this.totalAccessGroup = this.accessgroupByUserId.length;
    });
  }

  onSort(getSortValue: any) {
    this.isUpArrowActive = !this.isUpArrowActive;
    if (this.isUpArrowActive) {
      this.adminForm.patchValue({
        sortType: 'DESC',
        sortBy: getSortValue,
      });
    } else {
      this.adminForm.patchValue({
        sortType: 'ASC',
        sortBy: getSortValue,
      });
    }
    this.onFilter();
  }

  onFilter() {
    this.isFilterPagination = true;
    this.spinnerService.show();
    this.p = 0;
    this.adminForm.patchValue({
      userName: this.filteredSearchValue,
      accessGroups: this.adminForm.get('accessGroups').value,
      status: null,
      startRunDate: this.adminForm.get('startRunDate').value,
      endRunDate: this.adminForm.get('endRunDate').value,
      sortBy: this.adminForm.get('sortBy').value,
      sortType: this.adminForm.get('sortType').value,
      limit: this.itemsPerPage,
      page: this.p,
    });

    this.AdminApiService.getFilterRecords(this.adminForm.value).subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.responseData = res;
        this.fileData = this.responseData.result;
        this.filterData = [...this.fileData];
        this.totalItems = this.responseData.totalCount;
        this.isError = false;
      },
      (err: any) => {
        this.spinnerService.hide();
        this.isError = true;
      }
    );
  }

  onReset() {
    this.spinnerService.show();
    this.p = 0;
    this.isFilterPagination = false;
    this.isUpArrowActive = false;
    (<FormArray>this.adminForm.get('accessGroups')).clear();
    (<FormArray>this.adminForm.get('status')).clear();

    this.selectedUserGroup = [];
    this.selectedStatus = [];

    this.adminForm.patchValue({
      userName: null,
      accessGroups: null,
      status: null,
      startRunDate: null,
      endRunDate: null,
      sortBy: 'userName',
      sortType: 'ASC',
      limit: this.itemsPerPage,
      page: this.p,
    });
    this.filteredSearchValue = null;
    this.isError = false;
    this.fetchAllUserRecords();
  }

  onEdit(id: any, userName: any) {
    this.onEditId = id;
    this.isLoading = true;
    this.userName = userName;
    this.isEditPopupVisible = true;

    // get assigned chips on edit
    this.AdminApiService.accessGroupByUserIdOnEdit(id).subscribe((res: any) => {
      this.spinnerService.hide();
      this.getResponseGroup = res;
      this.accessgroupByUserIdChip = this.getResponseGroup.result.accessGroups;
      this.isLoading = false;
      this.getStatus = this.getResponseGroup.result.status;
      if (this.getResponseGroup.result.status === 'Active') {
        this.isActivate = true;
        this.isDeactivate = false;
        this.displayStyle = 'block';
        this.displayStyleOpacity = '1';
        this.onEditSearchDisabled = true;
        this.isUnAssignLinkVisible = true;
      } else if (this.getResponseGroup.result.status === 'Inactive') {
        this.isDeactivate = true;
        this.isActivate = false;
        this.displayStyle = 'none';
        this.displayStyleOpacity = '0.4';
        this.onEditSearchDisabled = false;
        this.isUnAssignLinkVisible = false;
      }
    });

    // get all Access Group with flag on edit with checkbox
    this.AdminApiService.getAllAccessGroupsWithFlagOnEdit(id).subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.getResponse = res;
        this.accessGroupData = this.getResponse.result;
        this.accessgroupByUserId = [...this.accessGroupData];
        this.totalAccessGroup = this.accessgroupByUserId.length;
      }
    );
  }

  onChipClose(value: any) {
    this.changesFlag = true;
    const index: number = this.accessgroupByUserIdChip.indexOf(value);
    if (index !== -1) {
      this.accessgroupByUserIdChip.splice(index, 1);
    }
    this.checkboxes.forEach((element) => {
      if (element.nativeElement.id === value) {
        element.nativeElement.checked = false;
      }
    });
    if (!this.accessgroupByUserIdChip.length) {
      this.changesFlag = false;
      this.isUnAssignLinkVisible = false;
    }
  }

  onUnAssignAll() {
    this.isStatusPopupVisible = true;
    this.headingText = 'Unassign All';
    this.statusMessage =
      'By removing all the user groups user will get deactivated.';
    this.statusMessageSure = 'Are you sure to proceed?';
    this.unAssignAll = true;
  }

  onCloseEdit() {
    this.isEditPopupVisible = false;
    this.changesFlag = false;
    this.adminForm.patchValue({
      searchAccessGroup: null,
    });
  }

  onCloseStatus() {
    this.isStatusPopupVisible = false;
  }

  onStatusCheck(value: any) {
    this.storeStatus = value;
    if (value === 'Active') {
      this.isStatusPopupVisible = true;
      this.headingText = 'User Status';
      this.statusMessage = 'Are you sure you want to Activate this User ?';
      this.statusMessageSure = null;
      this.setStatus = 'Active';
    } else if (value === 'Inactive') {
      this.isStatusPopupVisible = true;
      this.headingText = 'User Status';
      this.statusMessage = 'Are you sure you want to Deactivate this User ?';
      this.statusMessageSure = null;
      this.setStatus = 'Inactive';
    }
  }

  checkValue(value: any, e: any) {
    this.changesFlag = true;
    if (e.target.checked) {
      const control = new FormControl(value, Validators.required);
      this.accessgroupByUserIdChip.push(control.value);
      this.isUnAssignLinkVisible = true;
    } else {
      const index: number = this.accessgroupByUserIdChip.indexOf(value);
      if (index !== -1) {
        this.accessgroupByUserIdChip.splice(index, 1);
      }
      if (!this.accessgroupByUserIdChip.length) {
        this.changesFlag = false;
        this.isUnAssignLinkVisible = false;
      }
    }
  }

  onStatusCall() {
    if (this.unAssignAll) {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
        this.accessgroupByUserIdChip = [];
        this.setStatus = 'Inactive';
      });
      this.onAssign();
      this.isStatusPopupVisible = false;
      return;
    }

    this.isStatusPopupVisible = false;
    if (this.setStatus === 'Active') {
      this.storeStatus = 'Active';
      this.isDeactivate = false;
      this.isActivate = true;
      this.displayStyle = 'block';
      this.displayStyleOpacity = '1';
      this.onEditSearchDisabled = true;
    } else if (this.setStatus === 'Inactive') {
      this.storeStatus = 'Inactive';
      this.isActivate = false;
      this.isDeactivate = true;
      this.displayStyle = 'none';
      this.displayStyleOpacity = '0.4';
      this.onEditSearchDisabled = false;
    }
    this.getPage(this.p);

    // this call when we activate and deactivate users
    if (
      this.storeStatus === 'Inactive' ||
      this.accessgroupByUserIdChip.length > 0
    ) {
      this.spinnerService.show();
      this.AdminApiService.updateStatusOnEdit(
        this.onEditId,
        this.setStatus
      ).subscribe(
        (res: any) => {
          this.spinnerService.hide();
          this.isStatusPopupVisible = false;
          if (this.setStatus === 'Active') {
            this.storeStatus = 'Active';
            this.isDeactivate = false;
            this.isActivate = true;
            this.displayStyle = 'block';
            this.displayStyleOpacity = '1';
            this.onEditSearchDisabled = true;
            this.isUnAssignLinkVisible = true;
          } else if (this.setStatus === 'Inactive') {
            this.storeStatus = 'Inactive';
            this.isActivate = false;
            this.isDeactivate = true;
            this.displayStyle = 'none';
            this.displayStyleOpacity = '0.4';
            this.onEditSearchDisabled = false;
            this.isUnAssignLinkVisible = false;
          }
          this.getPage(this.p);
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onAssign() {
    this.spinnerService.show();
    let resultData = {
      userId: this.onEditId,
      userName: this.userName,
      status: this.setStatus,
      accessGroups: this.accessgroupByUserIdChip,
    };
    this.AdminApiService.updateAccessGroupById(resultData).subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.isToastVisible = true;
        setTimeout(() => {
          this.isToastVisible = false;
        }, 3000);
        this.changesFlag = false;
        this.headingText = null;
        this.statusMessageSure = null;
        if (this.unAssignAll) {
          this.isEditPopupVisible = false;
          this.utilService.goToTop();
        }
        this.getPage(this.p);
        this.unAssignAll = false;
        this.isUnAssignLinkVisible = true;
      },
      (err) => {
        this.spinnerService.hide();
        this.isToastVisible = false;
      }
    );
  }
}
