import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  p: number = 1;
  currentPage: any;
  itemsPerPage = 10;
  constructor(private readonly http: HttpClient) {}

  fetchAllDrafts(userId: string) {
    let sortParams = new HttpParams()
      .set('userId', userId.toString())
      .set('status', 'DRAFT');     

    return this.http.get(
      `${environment.apiUrl}/v1/jobrequests?limit=${this.itemsPerPage}&page=${this.p}`,
      {
        params: sortParams,
      }
    );
  }

  onDraftDelete(requestId: any) {
    return this.http.get(
      `${environment.apiUrl}/v1/deleteRequest?requestId=${requestId}`
    );
  }
}
