
export function getSelectedRecords(sourceArray: any, filterArray: any, filterBy: string): any {
  let matchedRecords = [];
  for (let source of sourceArray) {
    if (filterArray.includes(source[filterBy])) {
      matchedRecords.push(source);
    }
  }
  if (sourceArray == undefined || sourceArray.length == 0)
    return filterArray;
  return matchedRecords;
}

export function getSelectedRecordsFromArry(sourceArray: any, filterArray: any): any {
  if (filterArray == null || filterArray == undefined || filterArray.length == 0
    || sourceArray == null || sourceArray == undefined || sourceArray.length == 0)
    return filterArray;

  filterArray = filterArray.map((a: string) => { return a.toLowerCase() });
  let matchedRecords = [];
  for (let source of sourceArray) {
    if (filterArray.includes((source as string).toLowerCase())) {
      matchedRecords.push(source);
    }
  }
  return matchedRecords;
}
