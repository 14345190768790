<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div class="container">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a routerLink="/pairingAsProduct" class="navbar-brand">
        <img
          src="../../../../assets/images/dmint_color_logo.png"
          alt="CoolBrand"
          class="dmint_logo"
        />
      </a>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav menu_row" *ngFor="let iconHeader of iconHeaders">
          <a
            (click)="openLink(platformUrl + iconHeader.headerLinkPath)"
            routerLinkActive="active"
            class="nav-item nav-link"
            *ngIf="moduleByteMap"
          >
          <img
          [src]="'data:image/svg+xml;base64,' + moduleByteMap[iconHeader.headerName+'_'+iconHeader.menuImagePath]"
          alt="subscription_icon"
          />                                                                                                                                                                                                      
            <span>{{ iconHeader.headerName }}</span>
          </a>
        </div>

        <div class="navbar-nav right_row ms-auto">
          <ng-template #loggedOut
            ><button
              class="btn btn-primary custom-button-login"
              (click)="onLoginCall()"
            >
              Login
            </button></ng-template
          >
          <a
            *ngIf="auth.isAuthenticated$ | async; else loggedOut"
            class="nav-item nav-link dropdown-toggle"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><img
              src="../../../../assets/images/default_user_icon.svg"
              alt="icon"
              class="navbar_icon"
              onKeyPress=""            
            />
            <ul
              class="dropdown-menu ms-auto"
              aria-labelledby="dropdownMenuLink"
            >
              <li class="profile_section">
                <img
                  src="../../../../assets/images/default_user_icon.svg"
                  alt="icon"
                  class="profile_picture"
                />
                <ul class="profile_details">
                  <li class="name">{{ userName }}</li>
                </ul>
              </li>
              <hr />

              <li
              class="logout margin-bottom"
              (click)="openLink(platformUrl + sideHeader.headerLinkPath)"
              onKeyPress=""                                                            
              *ngFor="let sideHeader of sideHeaders">
              <a class="dropdown-item">                       
                <img
                  [src]="'data:image/svg+xml;base64,' + moduleByteMap[sideHeader.headerName+'_'+sideHeader.menuImagePath]"
                  alt="subscription_icon"
                />
                {{sideHeader.headerLabel}}</a>
              </li>            

              <li class="logout" onKeyPress ="" (click)="logout()">
                <a class="dropdown-item"
                  ><img src="../../../../assets/images/logout.svg" alt="" /> Logout</a
                >
              </li>
            </ul>
          </a>
        </div>  














      </div>
    </div>
  </nav>
</div>
