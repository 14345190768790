import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  p: any = 0;
  currentPage: any;
  itemsPerPage: any = 10;

  constructor(private readonly http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;',
    }),
  };

  getUserGroups() {
    return this.http.post<any>(`${environment.apiUrl}/v1/accessGroups`, null);
  }

  getAllUserRecords() {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/userAccessGroups?limit=${this.itemsPerPage}&page=${this.p}`,
      {}
    );
  }

  getUserListOnSearch() {
    return this.http.post<any>(`${environment.apiUrl}/v1/userList`, null);
  }

  getFilterRecords(filterForm: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/filterUserAccessGroups`,
      filterForm
    );
  }

  accessGroupByUserIdOnEdit(id: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/accessGroupsByUserId?userId=${id}`,
      {}
    );
  }

  updateStatusOnEdit(userId: any, status: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/updateStatusByUserId?userId=${userId}&status=${status}`,
      {}
    );
  }

  getAllAccessGroupsWithFlagOnEdit(userId: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/userAccessGroupWithFlag?userId=${userId}`,
      {}
    );
  }

  getAllAccessGroupsOnSearchInEdit(userId: any, accessGroupValue: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/accessGroupsByAccessGroup?userId=${userId}&accessGroup=${accessGroupValue}`,
      {}
    );
  }

  updateAccessGroupById(accessGroupData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/updateAccessGroupsByUserId`,
      accessGroupData
    );
  }
}
