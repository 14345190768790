import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/utils.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() notify = new EventEmitter();

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly utilService: UtilService) {}
  isAdmin: boolean = false;

  ngOnInit(): void {
    this.isAdmin = this.utilService.getUserRole();
  }

  goToHome() {
    this.notify.emit('goToHome');
    const snapshot = this.route.snapshot.routeConfig.path;
    if (snapshot === 'admin') {
      this.router.navigate(['/pairingAsProduct']);
    }
  }

  goToAdmin() {
    this.router.navigate(['/admin']);
  }

  isAdminPage() {
    if(window.location.href.indexOf('/admin') != -1){
      return true;
    } else {
      return false;
    }
  }
}
