import { catchError } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PairingStepFormApi {
  error = new Subject<string>();

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;',
    }),
  };

  getRegions( userId: any,selectedProductLine: any = [], selectedBusinessLine: any = []) {
    let bLine = selectedBusinessLine.length < 1 ? null : selectedBusinessLine[0];
    let pLine = selectedProductLine.length < 1 ? null : selectedProductLine[0];
    return this.http.post<any>(`${environment.apiUrl}/v1/regions?userId=${userId}&businessLine=${bLine}&productLine=${pLine}`
    , {});
  }

  getCountrys(regionId: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/countries`,
      { regionId },
      {}
    );
  }

  getCountrysByRegionCode(regionCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/countriesByCode`,
      { regionCode },
      {}
    );
  }

  getBusinessLines(region: any, productLines: any, userId: any) {
    return this.http.post<any>(`${environment.apiUrl}/v1/businessLines?userId=${userId}&productLine=${productLines}&regionCode=${region}`, null);
  }

  getProductLines(region: any, userId: any, businessLines: any = []) {
    let bLine = businessLines.length < 1 ? null : businessLines[0];
    return this.http.post<any>(`${environment.apiUrl}/v1/productlines?userId=${userId}&businessLine=${bLine}&regionCode=${region}`, null);
  }

  getMichelinBrands(plCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/brands?type=michelin&isMichelinExcluded=false&productLineCode=${plCode}`,
      null
    );
  }

  getSubSeasons(brandIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/subSeasons`,
      {
        brandIds,productLineCode,
      },
      {}
    );
  }

  getOtherSubSeasons(brandIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/subSeasons/y`,
      {
        brandIds,productLineCode,
      },
      {}
    );
  }

  getTyreUsage(brandIds: any, subSeasonIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/usage`,
      { brandIds, subSeasonIds, productLineCode},
      {}
    );
  }

  getOtherTyreUsage(brandIds: any, subSeasonIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/usage/y`,
      { brandIds, subSeasonIds, productLineCode},
      {}
    );
  }

  getMichelinTyreLines(brandIds: any, subSeasonIds: any, usageIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/tirelines/x`,
      { brandIds, subSeasonIds, usageIds, productLineCode},
      {}
    );
  }

  getOtherTyreLines(brandIds: any, subSeasonIds: any, usageIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/tirelines/y`,
      { brandIds, subSeasonIds, usageIds , productLineCode},
      {}
    );
  }

  getOtherBrands(isMichelinExcluded: boolean, plCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/brands?type=competitor&isMichelinExcluded=${isMichelinExcluded}&productLineCode=${plCode}`,
      null
    );
  }

  getOtherTireline(brandIds: any, productLineCode: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/tirelines/y`,
      { brandIds , productLineCode},
      {}
    );
  }

  getClusteringValues(
    regionId: number,
    parameterType: string,
    productLineCode: string
  ) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/parameters`,
      { regionId, parameterType, productLineCode },
      {}
    );
  }

  getDegradationFile(
    userId: any,
    clustering: any,
    degradation: any,
    regionId: number,
    productLineCode: any
  ) {
    const requestOptions: Object = {
      'Content-Type': 'application/json',
      responseType: 'blob' as 'json',
      observe: 'response',
    };

    return this.http.post<any>(
      `${environment.apiUrl}/v1/download/degradation-rule-sheet?${userId}`,
      { clustering, degradation, regionId, productLineCode },
      requestOptions
    );
  }

  uploadDegradationFile(formData: any, userId: string) {

    const headers = new HttpHeaders({
      userId: userId.toString(),
    });

    return this.http
      .post<any>(
        `${environment.apiUrl}/v1/upload/degradation-rule-sheet`,
        formData,
        { headers: headers }
      )
      .pipe(catchError(this.errorHandler));
  }

  getCompetitorFile(formData: any) {
    const requestOptions: Object = {
      'Content-Type': 'application/json',
      responseType: 'blob',
      observe: 'response',
    };

    return this.http.post<any>(
      `${environment.apiUrl}/v1/download/competitor-priority`,
      formData,
      requestOptions
    );
  }

  getCAIFile(formData: any) {
    const requestOptions: Object = {
      'Content-Type': 'application/json',
      responseType: 'blob',
      observe: 'response',
    };

    return this.http.post<any>(
      `${environment.apiUrl}/v1/download/cai-list`,
      formData,
      requestOptions
    );
  }

  getMissingCAIFile(file: any) {
    const requestOptions: Object = {
      'Content-Type': 'application/json',
      responseType: 'blob',
      observe: 'response',
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}/v1/downloadMissingCAI?file=${file}`,
        {},
        requestOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadCompetitorFile(formData: any, userId: string) {
    const headers = new HttpHeaders({
      userId: userId.toString(),
    });

    return this.http
      .post<any>(
        `${environment.apiUrl}/v1/upload/competitor-priority`,
        formData,
        {
          headers: headers,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadCAIFile(formData: any) {
    const requestOptions: Object = {
      'Content-Type': 'application/json',
      observe: 'response',
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}/v1/upload/cai`,
        formData,
        requestOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getDraftRecord(requestId: any) {
    if(null == sessionStorage.getItem('parentToken')) {
      return this.http.get(`${environment.apiUrl}/v1/jobrequests/${requestId}`);
    } else {
      const token = sessionStorage.getItem('parentToken').replace('token=','');
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('parentToken', token);
      const formData = {
          authId: sessionStorage.getItem('id'),
          requestId: 'Pairing-'+requestId
      };
      return this.http
        .post<any>(`${environment.apiUrl}/v1/editRequest`, formData, {
          headers: headers,
        })
        .pipe(catchError(this.errorHandler));
    }
  }

  onSubmit(formData: any, userId: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('responseType', 'text');
    headers = headers.append('userId', userId.toString());

    return this.http
      .post<any>(`${environment.apiUrl}/v1/submit`, formData, {
        headers: headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  onSaveDraft(formData: any, userId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'text',
      userId: userId.toString(),
    });

    return this.http
      .post<any>(`${environment.apiUrl}/v1/saveDraft`, formData, {
        headers: headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
