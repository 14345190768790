<ngx-spinner size="large" [type]="typeSelected"></ngx-spinner>

<app-header></app-header>

<div class="main_div">
  <form class="form" [formGroup]="adminForm">
    <div class="card_div">
      <div class="pageNameCont">
        <div class="icon">
          <img
            src="../../assets/images/adminSettings.svg"
            alt="adminSetting-icon"
          />
        </div>
        <h5>Admin Settings</h5>
      </div>
      <div class="filters_div">
        <div class="filters_icon_div">
          <img
            src="../../../../assets/images/Filters.svg"
            width="22px"
            height="22px"
            icon="filters"
            alt="filter_icon"
          />
        </div>
        <div class="filters_div_search dropdown_div">
          <div class="title">Search</div>
          <div class="ng-autocomplete">
            <ng-autocomplete
              [data]="searchRecord"
              [searchKeyword]="keyword"
              formControlName="userName"
              placeholder="Search for a user"
              (selected)="selectEvent($event)"
              (inputChanged)="onChangeSearch($event)"
              (inputFocused)="onFocused($event)"
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [minQueryLength]="3"
              [debounceTime]="1000"
            >
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.userName"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <div class="moreFilters">
          <div class="country_div1 dropdown_div">
            <div class="title">User Group(s)</div>

            <div class="dropdown">
              <ng-multiselect-dropdown
                [placeholder]="'Select User Group'"
                [settings]="userGroupDropdownSettings"
                [(ngModel)]="selectedUserGroup"
                [ngModelOptions]="{ standalone: true }"
                formArrayName="accessGroups"
                [data]="userGroups"
                (onSelect)="onUserGroupSelect($event)"
                (onDeSelect)="onUserGroupDeselect($event)"
                (onSelectAll)="onSelectAllUserGroups($event)"
                (onDeSelectAll)="onDeSelectAllUserGroups()"
              >
                {{ userGroups }}
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="last_run_on dropdown_div">
            <div class="title">Last Run On</div>
            <mat-form-field appearance="fill">
              <mat-date-range-input [rangePicker]="picker2">
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="startRunDate"
                  name="startRunDate"
                  (dateChange)="lastRunStartDate($event)"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="endRunDate"
                  name="endRunDate"
                  (dateChange)="lastRunEndDate($event)"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker2></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div class="country_div1 dropdown_div">
            <div class="title">Status</div>

            <div class="dropdown">
              <ng-multiselect-dropdown
                [placeholder]="'Select Status'"
                [settings]="statusDropdownSettings"
                [(ngModel)]="selectedStatus"
                [ngModelOptions]="{ standalone: true }"
                formArrayName="status"
                [data]="status"
                [disabled]="false"
                (onSelect)="onStatusSelect($event)"
                (onDeSelect)="onStatusDeselect($event)"
                (onSelectAll)="onSelectAllStatus($event)"
                (onDeSelectAll)="onDeSelectAllStatus()"
              >
                {{ status }}
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="filters_cta">
          <button class="tertiary_green_link_btn" (click)="onReset()">
            Reset
          </button>
          <button class="primary_green_btn apply_btn" (click)="onFilter()">
            Apply
          </button>
        </div>
      </div>
      <div class="users_table">
        <div class="totalCount">
          <p>
          
          </p>
        </div>
        <table class="usertable">
          <caption></caption>

          <thead class="grid">
            <th>
              <div class="sortCol">
                Users
                <img
                  src="../../../../assets/images/New folder/chevron-down.svg"
                  icon="chevrom_down"
                  alt="arrow-icon"
                  onkeypress=""
                  [title]="!isUpArrowActive ? 'Ascending' : 'Descending'"
                  [ngClass]="isUpArrowActive ? 'arrow-up-on-sort' : ''"
                  (click)="onSort('userName')"
                />
              </div>
            </th>
            <th>User Group(s)</th>
            <th>
              <div class="sortCol">
                Last run date
                <img
                  src="../../../../assets/images/New folder/chevron-down.svg"
                  icon="chevrom_down"
                  alt="arrow-icon"
                  onkeypress="" 
                  [title]="!isUpArrowActive ? 'Ascending' : 'Descending'"
                  [ngClass]="isUpArrowActive ? 'arrow-up-on-sort' : ''"
                  (click)="onSort('lastRunDate')"
                />
              </div>
            </th>
            <th>
              <div class="sortCol">
                Last modified date
                <img
                  src="../../../../assets/images/New folder/chevron-down.svg"
                  icon="chevrom_down"
                  alt="arrow-icon"
                  onkeypress="" 
                  [title]="!isUpArrowActive ? 'Ascending' : 'Descending'"
                  [ngClass]="isUpArrowActive ? 'arrow-up-on-sort' : ''"
                  (click)="onSort('lastModifiedDate')"
                />
              </div>
            </th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <ng-container *ngIf="isError">
            <div class="error-block">
              <p>{{ this.error }}</p>
            </div>
          </ng-container>
          <tbody *ngIf="filterData.length !== 0 && !isError">
            <tr
              class="grid"
              *ngFor="
                let i = index;
                let item;
                of: filterData
                  | paginate
                    : {
                        id: 'listing_pagination',
                        itemsPerPage: itemsPerPage,
                        currentPage: p,
                        totalItems: totalItems
                      }
              "
            >
              <td>
                <div class="userName">
                  {{ item.userName }}
                </div>
              </td>
              <td style="white-space: nowrap">
                <ng-container
                  *ngFor="let groupValue of item.accessGroups; let i = index"
                >
                  <div class="ugGroup">
                    <span class="ugChip" *ngIf="i < 2">{{ groupValue }} </span>
                    <span class="ugChip collected" *ngIf="i == 2"
                      >+{{ item.accessGroups.length - 2 }} others
                      <div class="otherUG">
                        <ng-container
                          *ngFor="
                            let groupValue of item.accessGroups;
                            let i = index
                          "
                        >
                          <p class="ugChip" *ngIf="i >= 2">
                            <span *ngIf="i >= 2">{{ groupValue }}</span>
                          </p>
                        </ng-container>
                      </div>
                    </span>
                  </div>
                </ng-container>
              </td>
              <td>{{ item.lastRunDate }}</td>
              <td>{{ item.lastModifiedDate }}</td>
              <td>
                <p
                  class="userStatus"
                  [ngClass]="item.status === 'Active' ? 'active' : 'inactive'"
                >
                  {{ item.status }}
                </p>
              </td>
              <td>
                <div class="editIcon" onkeypress="" (click)="onEdit(item.id, item.userName)">
                  <img
                    src="../../../../assets/images/edit_icon.svg"
                    width="22px"
                    height="22px"
                    icon="edit user"
                    alt="edit_icon"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="row"
          style="width: 100%"
          *ngIf="filterData.length !== 0 && !isError"
        >
          <pagination-controls
            id="listing_pagination"
            (pageChange)="getPage($event)"
            previousLabel="Prev"
            nextLabel="Next"
          >
          </pagination-controls>
        </div>
        
      </div>
    </div>
   
  </form>

  <div class="copyrights">
    &#64;DMINT Copyright {{ currentYear }}. All Rights Reserved.
  </div>

  <!-- Modal window container starts -->
  <div
    class="modal modal-backdrop"
    tabindex="-1"
    *ngIf="isEditPopupVisible"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <h4>{{ userName }}</h4>
            <div class="statusBox">
              <ng-container *ngIf="isActivate">
                <p class="status">Active</p>
              </ng-container>
              <ng-container *ngIf="isDeactivate">
                <p class="status status-error">Inactive</p>
              </ng-container>
              <ng-container *ngIf="isDeactivate && !isActivate">
                <a
                  class="tertiary_link_btn dontShow1"
                  (click)="onStatusCheck('Active')"
                  >Activate user</a
                ></ng-container
              >
              <ng-container *ngIf="isActivate">
                <a class="other_link_btn" (click)="onStatusCheck('Inactive')"
                  >Deactivate user</a
                >
              </ng-container>
            </div>
          </div>
          <div (click)="onCloseEdit()" onkeypress="">
            <img
              src="../../../../assets/images/close_icon.svg"
              class="close_icon"
              width="16px"
              height="16px"
              alt="icon"
            />
          </div>
        </div>
        <div class="modal-body">
          <div class="assignedUG">
            <p class="message1" *ngIf="isDeactivate && !isActivate">
              In order to start assigning user group(s), please activate the
              user.
            </p>
            <p
              class="message2"
              *ngIf="accessgroupByUserIdChip.length <= 0 && isActivate"
            >
              Please assign at least one user group to keep user Active.
              <!-- Please assign user groups from below to allow this user to use the
              application as required. -->
            </p>
            <div class="ugGroup">
              <ng-container *ngIf="isLoading">
                <p>Loading...</p>
              </ng-container>
              <ng-container *ngIf="!isLoading">
                <span
                  class="ugChip"
                  *ngFor="let accChip of accessgroupByUserIdChip"
                >
                  {{ accChip }}
                  <a class="removeUG" *ngIf="onEditSearchDisabled">
                    <img
                      src="../../../../assets/images/close.svg"
                      alt="close_icon"
                      #checkboxes
                      onkeypress="" 
                      (click)="onChipClose(accChip)"
                    />
                  </a>
                </span>
              </ng-container>
            </div>
          </div>
          <div class="assignNew">
            <form class="form" [formGroup]="adminForm">
              <div class="sectionHead">
                <div class="flexBox">
                  <h5>Add User Group(s)</h5>
                  <a
                    class="unassignAll"
                    *ngIf="isUnAssignLinkVisible"
                    (click)="onUnAssignAll()"
                    >Unassign All</a
                  >
                </div>

                <div class="filters_div_search dropdown_div customSearchBox">
                  <div class="title">Search</div>
                  <div class="ng-autocomplete">
                    <ng-autocomplete
                      [data]="accessGroupData"
                      [searchKeyword]="userGroupSearchkeyword"
                      formControlName="searchAccessGroup"
                      placeholder="Search user group"
                      (selected)="selectEventAccessGroup($event)"
                      (inputChanged)="onChangeAccessGroup($event)"
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="accessGroupnotFound"
                      [minQueryLength]="3"
                      [debounceTime]="1000"
                      [disabled]="!onEditSearchDisabled"
                    >
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.accessGroup"></a>
                    </ng-template>

                    <ng-template #accessGroupnotFound let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div
                class="sectionTwo"
                [ngClass]="
                  this.getResponseGroup.result.status === 'Inactive'
                    ? 'opac04'
                    : ''
                "
                [ngStyle]="{ opacity: displayStyleOpacity }"
              >
                <div class="totalCount addCustomGap">
                  <p>Showing {{ totalAccessGroup }} user groups</p>
                </div>

                <div class="scrollUG">
                  <div
                    class="checkMe"
                    *ngFor="let accessGroup of accessgroupByUserId"
                  >
                    <label [for]="accessGroup.accessGroup">
                      <input
                        type="checkbox"
                        class="checkBox"
                        [id]="accessGroup.accessGroup"
                        #checkboxes
                        [checked]="accessGroup.flag"
                        (change)="checkValue(accessGroup.accessGroup, $event)"
                        [disabled]="!onEditSearchDisabled"
                      />
                      <span class="styledCheck"></span>

                      {{ accessGroup.accessGroup }}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="primary_green_btn"
            (click)="onAssign()"
            [disabled]="!changesFlag"
          >
            Assign
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal window container ends -->

  <!-- Modal window container for status starts -->
  <div
    class="modal modal-backdrop"
    tabindex="-1"
    *ngIf="isStatusPopupVisible"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <h4>{{ headingText }}</h4>
          </div>
          <div (click)="onCloseStatus()" onkeypress="">
            <img
              src="../../../../assets/images/close_icon.svg"
              class="close_icon"
              width="16px"
              height="16px"
              alt="icon"
            />
          </div>
        </div>
        <div class="modal-body">
          <p>{{ statusMessage }}</p>
          <p>{{ statusMessageSure }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="primary_green_btn"
            (click)="onCloseStatus()"
          >
            No
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            class="primary_green_btn"
            (click)="onStatusCall()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal window container for status ends -->
</div>

<app-toast *ngIf="isToastVisible" [message]="successMsg"></app-toast>
